.create-email-template{
    .card-bg{
        background-color:var(--grayColor);
        padding:15px 15px;
    }
    .custom-table-sec{
        .sub-title{
            span{
                font-size:26px;
                font-weight:bold;
            }
        }
    }
}