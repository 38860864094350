
.input-field {
  border: none;
  border: 1px solid var(--inputBorder) !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  width: 100%;
  height: 40px;
  font-size: 14px;
  background-color: var(--whiteColor);
  // box-shadow: none !important;
  padding: 0 15px;

  &:focus-visible,
  &:focus {
    box-shadow: none !important;
    border:1px solid var(--inputBorder) !important;
    outline-offset:1px !important;
  }

  &:focus-visible {
    outline: none;
    border-color: var(--inputBorder) !important;
  }
}

input[disabled]{
  background-color:var(--tableLastBg);
}

.validation-text {
  color: #e30000;
  font-size: 13px;
  font-weight: 500;
}

.validation-star {
  color: #e30000;
  margin-left: 3px;
}

.checkbox-label-part {
  .validation-star {
    display: none;
  }
}