.email-page-main {
    .filter-sec {
        .form-field-sec {
            .search-btn {
                margin-top: 29px;
            }
        }
    }
}



.bottom-section {
    margin-top: 10px;

    h5 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    .bottom-dark-section {
        background: #FAFAFA;
        padding: 30px;

        .schedule-date-sec {
            margin-left: 40px;
        }
    }
}

.form-button-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-btn {}

    .right-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            &:last-child {
                margin-left: 40px;
            }
        }
    }
}

.email-setup-section {
    .checkbox-list {
        margin-top: 30px;

        .title-checkbox {
            display: flex;
            align-items: center;


            .input-label {
                font-weight: 700;
                font-size: 18px;
            }
        }

        .checkbox-list-desc {
            margin-top: 10px;

            .checkbox-detail-data {
                ul {
                    list-style-type: none;
                    li {
                        border: 1px solid var(--inputBorder);
                        padding: 5px 15px;
                        border-bottom: 0;
                        &:last-child{
                            border-bottom: 1px solid var(--inputBorder);
                        }
                        &.title-txt {
                            font-weight: 700;
                            font-size: 16px;
                            padding-left: 35px;
                        }
                        &.with-checkbox{
                            display: flex;
                            align-items: center;
                            .input-label{
                                margin-left: 7px;
                            }
                            &.bold{
                                font-weight: 600;
                                font-size: 16px;
                            }
                        }
                    }
                }


            }
            
        }
        .field-sec{
            
            margin-top: 5px;
            padding-left: 30px;
            padding-right: 30px;
        }

    }
    .last-bold-text{
        color: #00427A;
        font-size: 22px;
        
    }
}