.sidebar-section {
  background: var(--whiteColor);
  height: 100%;
  position: relative;
  width: 190px;
  height: calc(100vh - 102px);
  overflow: scroll;
  border-right: 1px solid var(--inputBorder);
  scrollbar-width: none;
  -ms-overflow-style: none;

  .custom-class .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .sidebar {
    .main-menus {
      .sidebar-toggler {
        display: none;
      }

      .sidebar-brand {
        display: block;
        padding: 28.5px 0;
        border-bottom: 1px solid var(--inputBorder);
        text-align: center;
        top: 0;
        position: fixed;
        width:190px;
        z-index: 99;
        background: white;

        img {
          width: 70%;
          margin: a auto;
        }
      }

      .sidebar-menu {
        margin-top: 110px;

        ul {
          list-style-type: none;
          padding-left: 0;

          &.sidebar-menu-list {
            margin-top: 15px;

            li {

              &.menu-item {

                a {
                  font-size: 100%;
                  color: var(--blackColor);
                  padding: 12px 15px;
                  display: flex;
                  align-items: center;

                  i {
                    font-size: 110%;
                  }

                  span {
                    padding-left: 15px;
                    font-size: 107%;
                    font-weight: 500;
                  }



                  &.menu-arrow {
                    position: relative;

                    &::before {
                      content: "";
                      position: absolute;
                      height: 12px;
                      width: 12px;
                      background: url(../../../assets/images/arrow.png);
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: contain;
                      right: 15px;
                      top: 52%;
                      transform: translateY(-50%) rotate(270deg);
                      -webkit-transform: translateY(-50%) rotate(270deg);
                      -moz-transform: translateY(-50%) rotate(270deg);
                      -ms-transform: translateY(-50%) rotate(270deg);
                      -o-transform: translateY(-50%) rotate(270deg);
                      transition: 0.5s all;
                      -webkit-transition: 0.5s all;
                      -moz-transition: 0.5s all;
                      -ms-transition: 0.5s all;
                      -o-transition: 0.5s all;
                      filter: invert(6%) sepia(10%) saturate(18%) hue-rotate(323deg) brightness(105%) contrast(88%);
                    }
                  }
                }

                &.active-menu,
                &:hover {
                  a {
                    background: var(--mainBgColor);
                  }
                }

                ul {
                  &.sidebar-dropdown {
                    padding-left: 30px;
                    height: 0;
                    overflow: hidden;
                    transition: 0.5s all;
                    -webkit-transition: 0.5s all;
                    -moz-transition: 0.5s all;
                    -ms-transition: 0.5s all;
                    -o-transition: 0.5s all;
                    opacity: 0;

                    li {
                      a {
                        background: none;
                        width: 100%;
                        display: block;
                        opacity: 0.8;

                        &::before {
                          display: none;
                        }
                      }

                      &:hover {
                        a {
                          color: var(--themeColor);
                          opacity: 1;
                        }
                      }

                      &:last-child {
                        a {
                          padding-top: 0;
                        }
                      }
                    }
                  }
                }



                &.active-menu {
                  a {
                    &::before {
                      transform: translateY(-50%) rotate(360deg);
                      -webkit-transform: translateY(-50%) rotate(360deg);
                      -moz-transform: translateY(-50%) rotate(360deg);
                      -ms-transform: translateY(-50%) rotate(360deg);
                      -o-transform: translateY(-50%) rotate(360deg);
                    }
                  }

                  .sidebar-dropdown {
                    height: auto;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}


// =============================================================================================================================================




/* Hide scrollbar */
.custom-class.no-scrollbar {}