.footer-main {
    background: var(--blackColor);
    color: var(--whiteColor);
    position: relative;

    .custom-width-foot {
        width: 80%;
        margin: 0 auto;
        padding: 30px 0;

        p {
            max-width: 480px;
            width: 100%;
            text-align: left;
            margin-bottom: 0;
        }
    }

    .footer-social {
        ul {
            padding-left: 0;
            list-style-type: none;
            display: flex;
            justify-content: center;
            column-gap: 15px;
            margin-bottom: 0;

            li {
                height: 40px;
                width: 40px;
                border: 1px solid var(--whiteColor);
                padding: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                transition: all 0.3s ease-in-out;

                a {
                    img {
                        width:20px;
                        filter: invert(1);
                        transition: all 0.3s ease-in-out;
                    }

                }

                &:hover {
                    background-color: var(--themeColor);
                }
            }
        }
    }
}

@media only screen and (max-width:1366px) {
    .footer-main {
        background: var(--blackColor);
        color: var(--whiteColor);
        position: relative;

        .custom-width-foot {
            width: calc(100% - 30px);

            p {
                width: 100%;
            
            }
        }

    }
}

@media only screen and (max-width:767px) {
    .footer-main {
        background: var(--blackColor);
        color: var(--whiteColor);
        position: relative;

        .custom-width-foot {
            width: calc(100% - 30px);
            p {
                width: 100%;
                margin-bottom:10px;
            }
        }
    }
}