.login-section {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--inputboxBg);
  z-index: 999;
  display: grid;
  place-items: center;
  overflow: auto;
  .right-form-section {
    .login-form-section {
      width: 470px;
      margin: 0 auto;
      padding: 55px 35px 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 111111;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      box-shadow: 0 2px 25px -10px rgba(65, 132, 234, 0.75);

      .logo-section {
        text-align: center;
        padding-bottom: 30px;

        img {
          width: 45%;
        }
      }

      .checkbox-part {
        display: flex;
        align-items: flex-start;
        column-gap: 15px;
      }

      .input-label-part {
        margin-bottom: 0;

        input {
          // border-top: none !important;
          // border-left: none !important;
          // border-right: none !important;
          border-radius: 0 !important;
          -webkit-border-radius: 0 !important;
          -moz-border-radius: 0 !important;
          -ms-border-radius: 0 !important;
          -o-border-radius: 0 !important;
          width: 100%;
        }
      }

      input[type="checkbox"] {
        min-width: 20px !important;
        min-height: 20px !important;
        &:before {
          width: 13px !important;
          height: 13px !important;
          background-image: url(http://localhost:3000/static/media/checkedicon.d6adbce052f1785a7393.svg) !important;
        }
      }

      .input-label {
        margin-top: 0;
        position: relative;
        top: -2px;
      }

      .nav-btn {
        text-align: right;
        padding: 0 !important;
        text-decoration: unset;
        &:hover {
          color: #00427ad9;
        }
      }

      h2 {
        font-weight: bold;
        margin-bottom: 30px;
      }
      .button-part {
        margin-top: 35px;
      }
    }
  }
}

@media (max-width: 600px) {
  .login-section {
    .right-form-section {
      .login-form-section {
        width: 90%;
      }
    }
  }
}

//Authentication Form

.auth-container {
  margin: 70px 0;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // z-index: 111111;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: 0 2px 25px -10px rgba(65, 132, 234, 0.75);
  .width {
    width: 200%;
  }
  h2 {
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
  }
}

@media only screen and (max-width: 1750px) {
  .auth-container {
    width: 400px;
    .width {
      width: 200%;
    }
    h2 {
      font-weight: bold;
      font-size: 25px;
      text-align: center;
      margin: 20px 0;
    }
  }
}
