.gmap-pricing-table {
    .input-margin-space {
        margin-bottom: 20px;
    }

    .custom-main-grid {
        display: grid;
        grid-template-columns: 22% 19% 20% 20% 19%;
        background-color: var(--grayColorLight);
        // padding: 20px;
        border-radius: 5px;
        column-gap: 0;
        row-gap: 0;
        overflow-y: auto;
        margin-bottom: 50px;
        padding-bottom: 20px;
        position: relative;
        z-index: 1;

        // &::after {
        //     position: absolute;
        //     content: '';
        //     background-color: var(--cpdSummaryTableBg);
        //     height: 80px;
        //     width: 100%;
        //     z-index: -1;
        // }
    }

    .section-title {
        margin-left: 0px;
        margin-top: 0 !important;
        padding: 10px;
        display: flex;
        align-items: center;

        .common-subtitle {
            font-weight: 700;
            font-size: 18px;
            color: var(--blackColor) !important;
            padding-bottom: 0;
            margin-bottom: 0;

        }

        &:after {
            border-bottom: unset;
        }

        &.custom-grid {
            background: var(--themeLightColor);
            margin-bottom: 20px;    
            align-items: center;
            flex-direction: row;
            &:first-child{
                padding-left: 20px;
            }
            &:last-child{
                padding-right: 20px;
            }
            &.blank-bg{
                background: transparent;
            }
        }
        
        &.category-title{
            padding-left: 20px;
        }

    }

    .custom-grid {
        padding: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        &.last-grid{
            padding-right: 20px;
        }
    }
}