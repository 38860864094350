// Pagination Start
.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  width: 80%;
  margin: 0 auto;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 100%;
  }
  .btn {
    width: 200px;
    font-size: 16px;
  }
}

.new-input-page {
  border: unset !important;
  border-radius: 5px;
  text-align: center;
  height: 15px;
  width:34px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 100%;
  font-weight: bold;
  background-color:transparent;

  &:focus {
    outline: unset !important;
    border: unset !important;
  }
}

// Pagination Ends
