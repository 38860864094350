.view-edit-admin-account-main {
    table {
        overflow: auto;
        position: relative;
        thead {
            tr {
                th {
                    padding: 25px 25px;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 25px 25px;
                }
            }
        }
    }
}