.nav-btn {
    padding: 10px 20px !important;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    font-weight: bold !important;
    text-align: center;
    min-width: 120px;
    width: max-content;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    cursor: pointer;
    border: none;
    font-size: 90%;
    background-size: 180% 100%;
    transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;

    // theme button
    &.theme-btn {
        color: var(--whiteColor);
        background: var(--themeColor);

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            color: var(--whiteColor);
            background-color: var(--themeColor);
        }
    }

    // success button
    &.success-btn {
        color: var(--whiteColor);
        background: var(--themeColor);

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            color: var(--whiteColor);
            background-color: var(--themeColorHover);
        }
    }

    // back button
    &.back-btn {
        color: var(--whiteColor);
        background: var(--blackColor);

        &:hover {
            transition: all .4s ease-in-out;
        }
    }

    // outline button
    &.outline-btn {
        color: var(--themeColor);
        border: 1.5px solid var(--themeColor);

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            color: var(--themeColorHover);
            border-color: var(--themeColorHover);
        }
    }

    // outline button



    // theme color hover button
    &.themecolor-hover-btn {
        color: var(--whiteColor);
        background: var(--themeColorHover);

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            color: var(--whiteColor);
            background-color: var(--themeColor);
        }
    }

    // theme color hover button
    &.success-btn {
        color: var(--whiteColor);
        background: var(--themeColor);

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
        }
    }

    // red button
    &.red-btn {
        color: var(--whiteColor);
        background: #a62934;

        &:hover {
            background-position: 100% 0;
            -o-transition: all .4s ease-in-out;
            -webkit-transition: all .4s ease-in-out;
            transition: all .4s ease-in-out;
            color: var(--whiteColor);
            background: #a62934;
        }
    }

    // primary-btn
    &.primary-btn {
        border-color: var(--primaryColor);
        background-color: var(--primaryColor);
        color: rgb(255 255 255/1);
        box-shadow: 0 10px 20px -10px #4361ee99;
    }

    &.secondary-btn {
        border-color: var(--secondaryColor);
        background-color: var(--secondaryColor);
        color: rgb(255 255 255/1);
        box-shadow: 0 10px 20px -10px #805dca99;
    }



    &.danger-btn {
        border-color: var(--dangerColor);
        background-color: var(--dangerColor);
        color: rgb(255 255 255/1);
        box-shadow: 0 10px 20px -10px #e7515a99;
    }

    &.warning-btn {
        border-color: var(--warningColor);
        background-color: var(--warningColor);
        color: rgb(255 255 255/1);
        box-shadow: 0 10px 20px -10px #e2a03f99;
    }

    &.info-btn {
        background-color: var(--infoColor);
        border-color: var(--infoColor);
        color: rgb(255 255 255/1);
        box-shadow: 0 10px 20px -10px #2196f399;
    }

}