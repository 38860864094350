:root {
  --themeColor: #00427A;
  --theme-secoundary-color: #a32a2e;
  --themeLightColor:#f2f9fc;
  --whiteColor: #ffffff;
  --blackColor: #1E1E1E;
  --footerColor: #1E1E1E;
  --inputboxBg: #FAFAFA;
  --accordianBorder: #D9D9D9;
  --grayColor: #ededed;
  --grayColorLight:#fafafa;
  --btn-grayColor: #969696;
  --inputBorder: #d8d8d8;
  --themeColorLight: #005193;
  --lightBg-Color: #eff6fd;
  --mainBgColor: #ffffff;

  --primaryColor: #4361ee;
  --secondaryColor: #805dca;
  --dangerColor: #e7515a;
  --warningColor: #e2a03f;
  --infoColor: #2196f3;
  --successColor: #00C32B;

  --primaryColorLight: #4463ee33;
  --secondaryColorLight: #805dcb33;
  --dangerColorLight: #e7505a33;
  --warningColorLight: #e2a24033;
  --infoColorLight: #2094f333;

  --box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --box-borderColor: #f0f2f6;


  // Bg-Gradient Color Start
  --theme-bg-g: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  --dark-bg-g: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  --orange-yellow-bg-g: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  --light-red-bg-g: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a, #FBB03B);
  --danger-bg-g: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
  --warning-bg-g: linear-gradient(to right, #25aae1, #4476eb, #04befe, #3f86ed);
  --info-bg-g: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
  // Bg-Gradient Color End
}