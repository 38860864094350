// Header Top Start
.main-top-title {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--grayColor);

  .left-section {
    .page-title {
      display: flex;
      align-items: center;

      .page-icon-part {
        margin-right: 15px;

        .icon-page {
          width: 50px;
          opacity: 0.8;
        }
      }

      h3 {
        font-size: 28px;
        font-weight: bold;
        color: var(--themeColor);
        margin-bottom: 0;
      }
    }
  }

  .right-section {
    width: 30%;

    .search-section {
      display: flex;
      align-items: center;
      position: relative;
      background: var(--whiteColor);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;

      .search-btn {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        img {
          width: 17px;
        }
      }

      input {
        border: none !important;
        width: 100%;
        padding-left: 40px;
        outline: none;
      }
    }
  }


}

// Header Top End