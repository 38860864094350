.email-template-management {
    .custom-table-sec {
        margin-top: 0;
        padding: 0 15px 15px;

        .searchbar {
            display: flex;
            align-items: center;
            column-gap: 10px;
            .search-section {
                display: unset;
            }
        }
    }

    .email-template {
        padding-top: 30px;
    }
}