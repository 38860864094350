.ckeditor-sec {
  position: relative;
  width: 100%;
  z-index: 0;

  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none !important;

    &:focus-visible,
    &:focus {
      border-color: var(--themeColor) !important;
    }
  }

  .ck.ck-editor__editable_inline p {
    font-size: var(--commonFont-small) !important;
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
  .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-rounded-corners {
    border-top-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    overflow: hidden;
    border: 1px solid var(--inputBorder);
  }

  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    overflow: auto;
  }

  .ck .ck-placeholder,
  .ck.ck-placeholder {
    position: relative;
    font-size: var(--commonFont-extraSmall) !important;
  }
}

.ck-content {
  height: 500px !important;
}


.ckeditor-section {
  width: 100%;
  position: relative;

  .ck {
    &.ck-toolbar-dropdown > .ck-dropdown__panel {
      max-width: 470px;

      @media only screen and (max-width: 1400px) {
        max-width: 400px;
      }
    }
  }
}