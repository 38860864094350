.model-center-main {
  display:flex;
  align-items:center;
  .modal-dialog {
    .modal-header {
      padding: 10px 40px 0;
      border: unset;
      // border-color: var(--themeColor);
      .modal-title {
        font-size: 100%;
        font-weight: bold;
        color: var(--themeColor);
        margin-bottom: 0;
        font-size: 32px;
        margin-top: 50px;
      }

      .btn-close {
        outline: none;
        box-shadow: none;
        transition: 0.5s all;
        -webkit-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        -o-transition: 0.5s all;
        --bs-btn-close-bg: url("../../../assets/images/close-icon.png");
        background: transparent var(--bs-btn-close-bg) center/30px auto no-repeat;
        opacity: 1;

        &:hover {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
    }

    .modal-body {
      padding: 40px 40px;

      .membership-directory-model {
        .council-model-description {
          text-align: left;
        }
      }
    }
  }

  &.w-30 {
    .modal-dialog {
      max-width: 30%;
    }
  }

  &.w-35 {
    .modal-dialog {
      max-width: 70%;
    }
  }


  &.w-40 {
    .modal-dialog {
      max-width: 40%;
    }
  }

  &.w-45 {
    .modal-dialog {
      max-width: 45%;
    }
  }

  &.w-50 {
    .modal-dialog {
      max-width: 50%;
    }
  }

  &.w-55 {
    .modal-dialog {
      max-width: 55%;
    }
  }

  &.w-60 {
    .modal-dialog {
      max-width: 60%;
    }
  }

  &.w-65 {
    .modal-dialog {
      max-width: 65%;
    }
  }

  &.w-70 {
    .modal-dialog {
      max-width: 70%;
    }
  }

  &.w-75 {
    .modal-dialog {
      max-width: 75%;
    }
  }

}


@media only screen and (max-width:1366px) {
  .model-center-main.w-35 .modal-dialog {
    max-width: 85%;
  }
}

@media only screen and (max-width:1199px) {
  .model-center-main.w-35 .modal-dialog {
    max-width: 90%;
  }

}

@media only screen and (max-width:991px) {
  .model-center-main.w-35 .modal-dialog {
    max-width: 90%;
  }

}

@media only screen and (max-width:767px) {
  .model-center-main.w-35 .modal-dialog {
    max-width: 95%;
  }
}

@media only screen and (max-width:585px) {
  .model-center-main.w-35 .modal-dialog {
    max-width: 95%;
    margin: 200px auto;
  }

  .model-center-main .modal-dialog .modal-body {
    padding: 30px 20px;
  }
}