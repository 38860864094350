// Table border-table-simple Start
.molgrid {
  table {
    width: 100%;
    border-radius: 5px !important;
    background: var(--whiteColor);
    overflow-y: auto;

    thead {
      background: var(--themeColorLight);

      tr {
        th {
          padding: 10px;
          text-align: left;
          text-transform: capitalize;
          font-size: 100%;
          color: var(--whiteColor);
          white-space: nowrap;

          .heading-shorting {
            position: relative;

            .shorting-part {
              // display: inline-block;
              button {
                background: transparent;
                border: none;
                outline: none;
                padding: 0 30px 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                span {
                  margin-right: 5px;
                  font-weight: bold;
                  padding: 0;
                  text-align: left;
                  text-transform: capitalize;
                  color: var(--whiteColor);
                }

                img {
                  height: 10px;

                  &.order-icon {
                  }
                }
              }

              span {
                &.sort-priority-number {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: var(--themeColor);
                  position: absolute;
                  right: -29px;
                  top: -9px;
                  color: var(--whiteColor);
                  height: 18px;
                  width: 30px;
                  line-height: 18px;
                  font-size: 11px;
                  border-radius: 7px;
                  -webkit-border-radius: 7px;
                  -moz-border-radius: 7px;
                  -ms-border-radius: 7px;
                  -o-border-radius: 7px;

                  .short-count {
                    display: flex;
                    align-items: center;
                    margin-left: 3px;

                    .cancel-short {
                      margin-left: 3px;
                      background: #ffffff9c;
                      width: 12px;
                      height: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 5px;
                      -webkit-border-radius: 5px;
                      -moz-border-radius: 5px;
                      -ms-border-radius: 5px;
                      -o-border-radius: 5px;
                      transition: 0.5s all;
                      -webkit-transition: 0.5s all;
                      -moz-transition: 0.5s all;
                      -ms-transition: 0.5s all;
                      -o-transition: 0.5s all;

                      &:hover {
                        background: var(--whiteColor);
                      }

                      img {
                        width: 6px;
                        height: 6px;
                      }
                    }
                  }
                }
              }

              &.short-column {
                button {
                  img {
                    &.order-icon {
                      transform: rotate(180deg);
                      -webkit-transform: rotate(180deg);
                      -moz-transform: rotate(180deg);
                      -ms-transform: rotate(180deg);
                      -o-transform: rotate(180deg);
                    }
                  }
                }
              }
            }
          }

          &:last-child {
            width: 150px;
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        // border: 1px solid var(--inputBorder) !important;
        border-left: 0 !important;
        border-right: 0 !important;

        &:nth-child(even) {
          background: #00427a1a;
        }

        td {
          padding: 10px 10px;
          font-size: 100%;

          &:last-child {
            width: 150px;
            text-align: center;

            div {
              justify-content: center;

              a {
                &:last-child {
                  margin-right: 0;
                }
              }
            }
          }

          .dropdown-action {
            position: relative;
            width: 100%;
            display: flex;

            .btn {
              padding: 12px;
              min-width: unset;
              background: #e0e0e0;

              img {
                margin: 0;
                filter: brightness(0);
                -webkit-filter: brightness(0);
                width: 15px;
              }
            }

            .dropdown-list {
              position: absolute;
              background: var(--whiteColor);
              width: 115px;
              border: 1px solid var(--inputBorder);
              z-index: 11;
              left: 50%;
              transform: translateX(-50%);
              top: 105%;
              display: none;
              -webkit-transform: translateX(-50%);
              -moz-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              -o-transform: translateX(-50%);

              ul {
                padding: 0;
                list-style-type: none;
                margin: 0;

                li {
                  text-align: left;

                  a {
                    display: block;
                    width: 100%;
                    padding: 0 15px;
                    border: 1px solid var(--inputBorder);
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;

                    &:hover {
                      background: var(--grayColor);
                    }
                  }
                }
              }
            }

            &.openAction {
              .dropdown-list {
                display: block;
              }
            }
          }
        }
      }
    }

    &.dark-table-simple {
      thead {
        background: var(--themeColor);

        tr {
          th {
            color: var(--whiteColor);
          }
        }
      }

      tbody {
        tr {
          border: none !important;

          &:nth-child(even) {
            background: #49ca3d0f;
          }
        }
      }
    }

    tbody {
      tr {
        border-left: 0 !important;
        border-right: 0 !important;

        td {
          &:last-child {
            a {
              cursor: pointer;

              img {
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s all;
                -webkit-transition: 0.5s all;
                -moz-transition: 0.5s all;
                -ms-transition: 0.5s all;
                -o-transition: 0.5s all;
                filter: grayscale(1);
              }

              &:hover {
                img {
                  filter: brightness(0);
                }
              }
            }
          }

          span {
            border-radius: 70px;
            font-size: 12px;
            margin: 0 auto;
            padding: 4px 15px;
            text-align: center;
            margin: 0 auto;

            &.primary {
              background-color: var(--primaryColorLight);
              color: var(--primaryColor);
            }

            &.secondary {
              background-color: var(--secondaryColorLight);
              color: var(--secondaryColor);
            }

            &.danger {
              background-color: var(--dangerColorLight);
              color: var(--dangerColor);
            }

            &.warning {
              background-color: var(--warningColorLight);
              color: var(--warningColor);
            }

            &.info {
              background-color: var(--infoColorLight);
              color: var(--infoColor);
            }

            &.dark {
              background-color: var(--darkColorLight);
              color: var(--darkColor);
            }

            &.success {
              background-color: var(--successColor);
              color: var(--darkColor);
            }
          }
        }
      }
    }
  }
}
// Table border-table-simple End
