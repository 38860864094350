.search-input {
  width: 450px;
}

.search-section {
  width:100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .left-desc-sec {
    width: 75%;
    padding-right: 25px;
  }
  .right-search-section {
    width: 25%;
  }
}

.search-part {
  position: relative;
  margin-right: 50px;
  width: 100%;
  input {
    border-radius:0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius:0px !important;
    -ms-border-radius:0px !important;
    -o-border-radius:0px !important;
    padding-right: 40px;
  }

  .search-btn {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    .btn {
      background: transparent;
      min-width: auto;
      padding: 0;

      img {
        width: 20px;
        filter: unset;
        -webkit-filter: unset;
        margin-right: 0;
      }
    }
    button{
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      img{
        margin-top: -3px;
      }
    }

  }
}

.serchbar-section {
  .card-section {
    .card-body-sec {
      margin-top: 0 !important;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .search-section {
    .right-search-section {
      .btn {
        min-width: 115px;
      }
    }
  }
}