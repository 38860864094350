@import "./Variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");

/* // Common Styles Start */

body {
  padding: 0;
  margin: 0;
  background-color: #0004430d;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #2d2d2d;
  overflow: hidden;
  overflow-y: auto;
}

a {
  transition: 0.5s all;
  color: var(--themeColor);
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -ms-transition: 0.5s all;
  -o-transition: 0.5s all;
  text-decoration: none;

  &:hover {
    color: var(--darkColor);
  }
}

.input-label-part {
  width: 100%;

  .input-label {
    font-weight: unset;
    font-size: 16px;
    line-height: inherit;
    margin-bottom: 0px;
    display: inline-block;
    margin-bottom: 5px;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: unset;
  border-radius: unset;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: unset;
  border-radius: unset;
  background: var(--themeColor);
}

// custom-margin start
.m-0 {
  margin: 0 !important;
}

// Margin Left
.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

// Margin-Right
.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

// Margin-top
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

// Margin-bottom
.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

// custom-margin end

// custom-padding start
.p-0 {
  padding: 0 !important;
}

// Padding Left
.pl-1 {
  margin-left: 0.25rem;
}

.pl-2 {
  margin-left: 0.5rem;
}

.pl-3 {
  margin-left: 0.75rem;
}

.pl-4 {
  margin-left: 1rem;
}

.pl-5 {
  margin-left: 1.25rem;
}

// Padding-Right
.pr-1 {
  margin-right: 0.25rem;
}

.pr-2 {
  margin-right: 0.5rem;
}

.pr-3 {
  margin-right: 0.75rem;
}

.pr-4 {
  margin-right: 1rem;
}

.pr-5 {
  margin-right: 1.25rem;
}

// Padding-top
.pt-1 {
  margin-top: 0.25rem;
}

.pt-2 {
  margin-top: 0.5rem;
}

.pt-3 {
  margin-top: 0.75rem;
}

.pt-4 {
  margin-top: 1rem;
}

.pt-5 {
  margin-top: 1.25rem;
}

// Padding-bottom
.pb-1 {
  margin-bottom: 0.25rem;
}

.pb-2 {
  margin-bottom: 0.5rem;
}

.pb-3 {
  margin-bottom: 0.75rem;
}

.pb-4 {
  margin-bottom: 1rem;
}

.pb-5 {
  margin-bottom: 1.25rem;
}

// custom-padding end

// Common Styling Start

.main-page-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--whiteColor);

  .top-sec {
    display: flex;
  }

  .middle-page-section {
    width: calc(100% - 190px);

    .center-content-part {
      width: 100%;
      background: var(--mainBgColor);
    }

    .center-content-part {
      height: calc(100vh - 205px);
      overflow: hidden;

      .content-desc-section {
        .center-container {
          padding: 30px;
          height: calc(100vh - 269px);
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

.page-title {
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: var(--themeColor);
  }
}

// common tasklist page style start
.total-search-main {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto;
  margin: 30px 0 20px;

  .total-part {
    h5 {
      color: var(--blackColor);
      font-size: 25px;
      font-weight: bold;
    }
  }

  .search-section {
    width: 30%;
  }

  .search-part {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: end;
    margin-right: 0;
  }
}

// common tasklist page style end

// common view tasklist page style start
.theme-color {
  color: var(--themeColor);
}

.font-weight-bold {
  font-weight: bold;
}

.tasklist-form {
  .form-tab-header {
    background-color: var(--inputboxBg);
    border-radius: 5px;
    padding: 15px 20px;
    border-bottom: 1px solid var(--accordianBorder);
    font-weight: bold;
    color: var(--themeColor);

    h4 {
      font-size: 20px !important;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  .base-Pricing h4 {
    padding: 15px 20px;
    font-size: 20px !important;
    font-weight: bold;
    color: var(--themeColor) !important;
  }

  .separation-line {
    hr {
      margin-bottom: 0px !important;
    }
  }
}

.common-accordian-view-design {
  .accordion {
    border-left: unset !important;
    margin-bottom: 50px;

    border-left: unset !important;
    margin-bottom: 25px;

    .accordion-item {
      background-color: var(--inputboxBg);
      border-radius: 5px;
      border: unset !important;
      margin-bottom: 25px;

      h2 {
        border-radius: 5px;

        button {
          font-size: 20px;
          font-weight: bold;
          color: var(--themeColor);

          &:focus {
            box-shadow: unset !important;
            outline: unset !important;
          }
        }
      }
    }

    .accordion-button {
      padding: 18px;
      background-color: transparent !important;

      &::after {
        margin-left: auto;
        content: "";
        font-weight: normal;
        background-image: url(../assets/images/add.png) !important;
        transition: var(--bs-accordion-btn-icon-transition);
        color: var(--darkColor) !important;
        background-size: 20px;
      }

      &:not(.collapsed) {
        &::after {
          content: "";
          font-size: 50px;
          font-weight: normal;
          background-image: url(../assets/images/minus-sign.png) !important;
          transform: unset !important;
          background-size: 17px;
        }
      }

      &:not(.collapsed) {
        background-color: unset !important;
        box-shadow: unset !important;
      }
    }

    .accordion-body {
      padding: 20px 40px;
      border-top: 1px solid var(--accordianBorder);
    }
  }
}

// edit profile page style end
.separation-line {
  position: relative;

  hr {
    color: var(--themeColor) !important;
    border-top: 50px solid var(--whiteColor) !important;
    opacity: 1 !important;
    position: relative;
    margin-left: -37px;
    display: block;
    width: 106%;
    margin-bottom: 35px;
  }
}

// common view tasklist page style start
.common-subtitle {
  font-weight: 600;
}

.sub-section-title {
  .common-subtitle {
    padding-left: 15px;
  }
}

.big-title-theme {
  .common-subtitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--themeColor);
  }
}

.break-radio {
  .input-field-sec {
    .radio-label-part {
      display: flex;
      flex-direction: column;

      .radio-label {
        margin-left: 0;
      }
    }
  }
}

.checkbox-part {
  display: flex;
  align-items: center;

  .checkbox {
    display: flex;
    align-items: center;
  }
}

.main-checkbox {
  .input-field-sec {
    .checkbox-label-part {
      .checkbox-part {
        display: flex;
        align-items: center;

        .checkbox {
          display: flex;
          align-items: center;
        }

        .input-label-title {
          .input-label {
            font-weight: 600;
            margin-top: 0;
          }
        }
      }
    }
  }
}

.inner-checkbox-left-space {
  padding-left: 40px;
}

.big-note-txt {
  font-weight: 600;
  color: var(--themeColor);
  font-size: 115%;
}

// Edit IndividualMember Page style start
.paid-lable {
  color: var(--whiteColor);
  background-color: var(--successColor);
  display: inline-block;
  padding: 7px 10px;
  position: absolute;
  right: -42px;
}

.pending-lable {
  color: var(--whiteColor);
  background-color: var(--warningColor);
  display: inline-block;
  padding: 7px 10px;
  position: absolute;
  right: -42px;
}

.download-btn-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-weight: 700;
  }

  button {
    img {
      filter: brightness(100);
      width: 17px;
    }
  }
}

.display-img {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80%;
    margin: 0 auto;
  }
}

.custom-button {
  margin-top: 7px;

  &.payment {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}

// dropdown and emailmanagement
.dropdown-management-main {
  .card-bg {
    background-color: var(--grayColor);
    padding: 15px 15px;
  }

  .custom-table-sec {
    margin-top: 40px;
    background-color: var(--grayColor);
    padding: 15px 15px;

    .sub-title {
      span {
        font-size: 26px;
        font-weight: bold;
      }
    }

    .button-part {
      padding-top: 10px;
    }
  }
}

.view-individual-member {
  table {
    thead {
      tr {
        th {
          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 45%;
          }
        }
      }
    }
  }
}

.account-info-title {
  font-family: Arial, sans-serif;
  /* or any similar sans-serif font */
  font-size: 24px;
  /* Adjust the size as needed */
  font-weight: bold;
  /* The text appears to be bold */
  color: #003366;
  /* The text is a dark blue color */
  text-align: left;
  /* The text is aligned to the left */
}

@media only screen and (max-width: 1366px) {
  .custom-button {
    margin-top: 15px;
  }
}

.input-textfield {
  input {
    height: 43px !important;
  }
}

.upload-btn {
  margin-top: -12px;

  .validation-text {
    position: absolute;
    left: 225px;
    top: 80px;
  }

  .input-label-part {
    .custom-file-uploader {
      .file-name-clear-btn {
        .custom-file-selector-design {
          p {
            display: none;
          }
        }
      }
    }
  }
}

.comments-title {
  .common-subtitle {
    margin-top: -20px;
  }
}

.invoice-detail-model {
  display: flex !important;
  //z-index: 9999;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1055 !important;
}

.custom-table {
  margin: 10px 0;

  .label {
    margin-left: 20px;
    font-size: 18px;
  }

  tbody {
    tr {
      td {
        span {
          font-size: 16px !important;
        }
      }
    }
  }
}

.hidden-upload-input {
  display: none;
}

.margin-top-uploadbtn {
  margin: 28px 0;
}

.input-group-first {
  width: 63rem;
  width: 50%;
}

.second-input {
  width: 100%;
}